import Portal from "../../../../components/generic/portal/Portal";
import VehicleCard from "../../../../components/generic/card/VehicleCard";
import datasetParser from "../../../../utils/dataset/datasetParser";
import {useHandleFormParams} from "../hooks/useHandleFormParams.ts";
import {useEffect} from "react";
import Row from "../../../../features/containers/components/Row.tsx";
import {getTranslatedRoute} from "../../../../routing/functions/getTranslatedRoute.ts";
import useLocale from "../../../../hooks/selector/useLocale.ts";
import useTranslation from "../../../../features/translation/hooks/useTranslation.ts";
import IcounDoubleChevron from "../../../../components/generic/icons/IcounDoubleChevron.tsx";
import {useMozioVehicleObject} from "../features/MozioVehicleCards/constants/useMozioVehicleObject.ts";
import MozioVehicleCard from "../features/MozioVehicleCards/components/MozioVehicleCard.tsx";
import Translation from "../../../../features/translation/components/Translation.tsx";
import {PRICE_TYPES} from "../features/MozioVehicleCards/constants/PRICE_TYPES.ts";
import {setFromPlace, setToPlace} from "../../../../reducers/formSlice.ts";
import {useAppDispatch} from "../../../../hooks/dispatch/useAppDispatch.ts";

interface VehicleCardsPortalType {
    type: string,
    typeId: number,
    minNoOfPersons: number,
    maxNoOfPersons: number,
    discountedPrice: string,
    returnPrice: string,
    photoUrl: string,
    info: string[]
}

function VehicleCardsPortal() {
    const {vehicles, priceType} = datasetParser({id: "vehicle-group-portal"}) as Record<string, VehicleCardsPortalType>;
    const placeholder = document.getElementById('vehicle-placeholder');
    const dispatch = useAppDispatch();

    const {
        populateSearchForm
    } = useHandleFormParams();
    const {getPath} = getTranslatedRoute();
    const locale = useLocale();
    const tomorrow = useTranslation('booking.path_params.tomorrow');
    const seeAllResults = useTranslation('booking.chooseVehicle.seeAllResults');
    const {MozioVehicleObject} = useMozioVehicleObject();

    const fallbackObject = Object.entries(vehicles).length > 0 ? vehicles : MozioVehicleObject;

    useEffect(() => {
        placeholder?.remove();
        populateSearchForm();
    }, []);

    const {
        searchUrl: {
            fromId,
            fromSlug,
            fromLabel,
            fromIsAirport,
            toId,
            toSlug,
            toLabel,
            toIsAirport,
        },
        mozioApiStatus
    } = datasetParser({id: "vehicle-group-portal"})

    useEffect(() => {
        if (mozioApiStatus === 'false') {
            dispatch(setToPlace({
                placeName: toLabel as string,
                placeId: toId as string,
                isStrict: true,
                isAirport: toIsAirport
            }))

            dispatch(setFromPlace({
                placeName: fromLabel as string,
                placeId: fromId as string,
                isStrict: true,
                isAirport: fromIsAirport
            }))
        }
    }, []);

    return (
        <Portal
            domNodeId="vehicle-group-portal"
        >
            <Row alignEnd className={'card__seeAll'}>
                <a href={`/${locale}/${getPath("booking")}/${fromSlug}-${fromId}/${toSlug}-${toId}/${tomorrow}`}>
                    {seeAllResults}
                    <IcounDoubleChevron fill={'#4A79E2'} chevronOrientation={"right"}/>
                </a>
            </Row>
            <Row justify wrap gap className={'card__vehicle--list'}>
                {priceType && priceType.includes(PRICE_TYPES.PRICE_PER_ROUTE) && Object.entries(vehicles).length > 0 ? (
                    Object.entries(vehicles).map(([key, vehicle]) => {
                        return (
                            <VehicleCard
                                key={key}
                                vehicleGroup={vehicle.type}
                                vehicleGroupId={vehicle.typeId}
                                vehicleImagePath={vehicle.photoUrl}
                                vehicleMinPaxCapacity={vehicle.minNoOfPersons}
                                vehicleMaxPaxCapacity={vehicle.maxNoOfPersons}
                                vehiclePrice={vehicle.discountedPrice}
                                vehicleAmenities={vehicle.amenities}
                                vehicleRating={vehicle.rating}
                                vehicleModelMake={vehicle.modelMake}
                                vehicleRatingCount={vehicle.ratingCount}
                            />
                        );
                    })
                ) : (
                    <>
                        {Object.values(fallbackObject).map((vehicle) => (
                            <MozioVehicleCard
                                key={vehicle.typeId}
                                vehicleGroup={vehicle.typeId}
                                vehicleGroupType={vehicle.type}
                                vehicleImagePath={vehicle.photoUrl}
                                vehicleMinPaxCapacity={vehicle.minNoOfPersons}
                                vehicleMaxPaxCapacity={vehicle.maxNoOfPersons}
                                vehicleRating={vehicle.rating}
                                vehicleRatingCount={vehicle.ratingCount}
                                vehicleModelMake={vehicle.modelMake}
                                vehiclePrice={vehicle?.discountedPrice}
                            />
                        ))}
                    </>
                )}
            </Row>
            <Row>
                {!priceType.includes(PRICE_TYPES.PRICE_PER_ROUTE) && mozioApiStatus === 'true' ? (
                    <>
                        *<Translation translationKey={'stationToStation.pricesMayVary'}/>
                    </>
                ) : (
                    <>
                        <Translation translationKey={'stationToStation.noResults'}/>
                    </>
                )}
            </Row>
        </Portal>
    )
}

export default VehicleCardsPortal;