import {useEffect} from "react";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import {clear as clearStripe} from "../../../reducers/stripeSlice.ts"
import {useParams} from "react-router";
import {getDateRequestFormat} from "../functions/getDateRequestFormat.ts";
import {cloneDeep, toLower} from "lodash";
import useLocale from "../../../hooks/selector/useLocale.ts";
import {LocaleType} from "../../../types/Locale.type.ts";
import useNavigateToHome from "../../../routing/navigation/useNavigateToHome.ts";
import {useLazyInitialSearchQuery} from "../../../api/reducers/apiSlice.ts";
import getCityFromSlug from "../functions/getCityFromSlug.ts";
import {getStationIdFromParam} from "../functions/getStationIdFromParam.ts";
import {setFromPlace, setFromStation, setToPlace, setToStation} from "../../../reducers/formSlice.ts";
import {setLatestSearchFormUsed, setSearchedWithStations} from "../reducers/functions/searchResultsSlice.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";

export default function useFetchDataOnPageRerender(fetchedGbtData: any) {
    const dispatch = useAppDispatch();
    const locale = toLower(useLocale()) as LocaleType;
    const {navigateToHome} = useNavigateToHome();
    const form = useAppSelector((state) => state.form);
    const [
        getGbtVehiclesData,
        gbtData,
    ] = useLazyInitialSearchQuery()

    const {
        data,
        isLoading,
        isFetching,
        isError,
        error,
        isUninitialized,
        status,
        originalArgs
    } = gbtData || {}

    const {
        fromSlug,
        toSlug,
        date: pickUpDateTime,
        passengers: numberOfPeople,
    } = useParams()

    const getInitialSearch = () => {
        if (!!fromSlug && !!toSlug && !!pickUpDateTime && !!numberOfPeople) {
            const from = getCityFromSlug(fromSlug)
            const to = getCityFromSlug(toSlug)
            const fromId = getStationIdFromParam(fromSlug)
            const toId = getStationIdFromParam(toSlug)
            dispatch(setSearchedWithStations(true))

            getGbtVehiclesData({
                startLocation: from,
                destinationLocation: to,
                startStationId: fromId,
                destinationStationId: toId,
                locale,
                pickUpDateTime: new Date(new Date(getDateRequestFormat(pickUpDateTime) as string).setHours(23, 59, 59, 999)).toISOString() ?? "",
                returnPickUpDateTime: "",
                numberOfPeople: parseInt(numberOfPeople, 10)
            }).then((response) => {
                let updatedSearchForm = cloneDeep(form);
                dispatch(setFromStation(response.data.data?.pickupInfo?.pickUpStation));
                dispatch(setToStation(response.data.data?.dropOffInfo?.dropOffStation));
                if (response.data && response.data.data.dropOffInfo?.isAirport && response.data.data.integrations?.mozio?.isMozioEnabled) {
                    const dropOffInfo = response.data.data.dropOffInfo
                    dispatch(setToPlace({
                        placeName: dropOffInfo.dropOffPlace,
                        placeId: dropOffInfo.dropOffPlaceId,
                        isStrict: dropOffInfo.isAirport
                    }))
                    updatedSearchForm = {
                        ...updatedSearchForm,
                        toPlace: {
                            placeName: dropOffInfo.dropOffPlace,
                            placeId: dropOffInfo.dropOffPlaceId,
                            isStrict: dropOffInfo.isAirport
                        }
                    }
                }

                if (response.data && response.data.data.pickupInfo?.isAirport && response.data.data.integrations?.mozio?.isMozioEnabled) {
                    const pickupInfo = response.data.data.pickupInfo
                    dispatch(setFromPlace({
                        placeName: pickupInfo.pickUpPlace,
                        placeId: pickupInfo.pickUpPlaceId,
                        isStrict: pickupInfo.isAirport
                    }))
                    updatedSearchForm = {
                        ...updatedSearchForm,
                        fromPlace: {
                            placeName: pickupInfo.pickUpPlace,
                            placeId: pickupInfo.pickUpPlaceId,
                            isStrict: pickupInfo.isAirport
                        }
                    }
                }

                if (response.data && !response.data.data.integrations?.mozio?.isMozioEnabled) {
                    const pickUpStation = response.data.data.pickupInfo.pickUpStation;
                    const dropOffStation = response.data.data.dropOffInfo.dropOffStation;

                    dispatch(setToPlace({
                        placeName: dropOffStation.label,
                        placeId: dropOffStation.id,
                        isStrict: true
                    }))
                    updatedSearchForm = {
                        ...updatedSearchForm,
                        toPlace: {
                            placeName: dropOffStation.label,
                            placeId: dropOffStation.id,
                            isStrict: true
                        }
                    }

                    dispatch(setFromPlace({
                        placeName: pickUpStation.label,
                        placeId: pickUpStation.id,
                        isStrict: true
                    }))
                    updatedSearchForm = {
                        ...updatedSearchForm,
                        fromPlace: {
                            placeName: pickUpStation.label,
                            placeId: pickUpStation.id,
                            isStrict: true
                        }
                    }
                }

                dispatch(setLatestSearchFormUsed(updatedSearchForm))
            })
        } else {
            navigateToHome()
        }
    }

    // Use effect for initial search so it runs only once
    useEffect(() => {
        dispatch(clearStripe())

        // just in case there is no vehicles data here, rerun the query with data from the params
        // this should run on page refresh and redirects from GBB
        if (!fetchedGbtData) {
            getInitialSearch();
        }
    }, [])

    return {
        pageRerenderFetchedGbtData: data,
        pageRerenderFetchedGbtIsUninitialized: isUninitialized,
        pageRerenderFetchedIsLoading: isLoading || isFetching,
        pageRerenderFetchedGbtIsError: isError,
        pageRerenderFetchedError: error,
        pageRerenderFetchedStatus: status,
        pageRerenderFetchedOriginalArgs: originalArgs
    }
}